import { createRoot } from "react-dom/client";
import { createElement } from "react";
import { App } from "./app.component";
import "./styles.css";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const element = document.getElementById("root")!;
const root = createRoot(element);

root.render(
    <TonConnectUIProvider manifestUrl="https://test.game.ivklim.com/tonconnect-manifest.json">
        <App />
    </TonConnectUIProvider>
);
